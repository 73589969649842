/* === CSS variables === */

:root {
  --theme-spacing-unit: 10pt;
  --theme-spacing-unit-x2: 20pt;
  --theme-palette-background-paper: #fff;
  --footer-height: 250px;
  --max-width: 1600px;
  --gencat-red: #c00000;
  --gencat-black: #333;
  --gencat-gray-text: #999;
  --gencat-gray-line: #ddd;
  /* Segons la guia hauria de ser #F5F5F5 */
  --gencat-gray-background: #efefef;
}

.hidden {
  display: none;
}

.wrap {
  flex-flow: wrap;
}

.padding-one {
  padding: 1rem;
}

section .no-padding-h {
  padding-left: 0;
  padding-right: 0;
}

section .small-padding-h {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.wider {
  flex-grow: 1;
}

.flow-v {
  flex-flow: column;
}

a {
  color: var(--gencat-red);
}

#root {
  position: relative;
  min-height: 100vh;
  padding-bottom: var(--footer-height);
}

#root.embed {
  padding-bottom: 0.5rem;
}

.filler {
  height: 4rem;
}

div .small-media-element {
  color: var(--gencat-red);
  padding: 4px;
}

div .small-media-element:first-of-type {
  margin-left: 5px;
}

main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  align-items: start;
  max-width: var(--max-width);
  margin: 0 auto 1.5rem auto;
  padding: 1rem;
}

.single-column {
  grid-template-columns: 1fr;
}

main.embed {
  margin: inherit;
}

.embed-link-container {
  width: 100%;
  text-align: center;
}

/* Singe column below this with: */

@media (max-width: 840px) {
  main {
    grid-template-columns: 1fr;
  }
}

/* Small text on year labels */

@media (max-width: 440px), (min-width: 840px) and (max-width: 1020px) {
  main .MuiStepLabel-label {
    font-size: 0.6rem;
  }
  main .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 8px;
  }
}

/* === Search bar === */

.search-bar {
  display: grid;
  grid-template-columns: 3rem 1fr 3rem;
  align-items: center;
}

.search-bar .search-text {
  margin: 0 1rem;
}

/* === Header (defaults to small devices) === */

header .search-bar {
  padding: 1rem;
  background-color: #4b4b4b;
}

.search-bar .search-text {
  background-color: #fff;
}

header .main-header-block {
  flex-grow: 1;
  overflow: hidden;
  max-width: 100rem;
  margin: auto;
}

header .top-bar, .nav-bar {
  display: none;
}

/* === Header and layout for big devices === */

@media (min-width: 1000px) {
  #root header {
    position: inherit;
  }
  .filler {
    position: absolute;
    top: 0;
    height: 0;
  }
  header .top-bar {
    margin: 0.7rem 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1fr;
  }
  header .search-bar {
    padding: 0;
    background-color: inherit;
    grid-template-columns: 1fr 3rem;
  }
  .search-bar .search-text {
    grid-column: 1;
    grid-row: 1;
    margin: 0;
    max-width: 18rem;
    height: 36px;
    justify-self: end;
    padding-left: 0.5rem;
    border: 1px solid #ddd;
  }
  .search-bar .search-btn {
    padding: 0;
    margin: 0;
    width: 36px;
    height: 100%;
    background-color: var(--gencat-red);
    border-radius: 0;
  }
  header .nav-bar {
    display: block;
    margin-top: 1rem;
  }
  header .nav-bar>li {
    margin: 0;
    padding: 0;
    border-left: 1px solid #fff;
    float: left;
    list-style: none;
  }
  header .nav-bar>li>.nav-label {
    padding: 0.5em 1.125em;
    font-size: 1em;
    font-weight: 400;
    cursor: pointer;
  }
  header .nav-bar>li>.nav-label[current=true] {
    background-color: #fff;
    color: var(--gencat-black);
  }
  header .main-menu-button {
    display: none;
  }
  header .main-title {
    margin: 0.7rem 2rem;
    font-size: 28px;
    font-weight: 600;
  }
  header .logo-gencat {
    background: url("assets/gencat_blanc.png") no-repeat 0 0;
    width: 170px;
    height: 2em;
    text-indent: -555em;
    width: 100%;
    display: block;
    text-decoration: none;
    outline: none;
    box-sizing: border-box;
    cursor: pointer;
  }
  header .search-btn-small {
    display: none;
  }
}

/* === Main components === */

.presenta {
  grid-column: 1 / -1;
  padding: 1rem;
}

.presenta .filler {
  height: 4rem;
}

.paper {
  padding: 1.5rem;
}

.seccio plain-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-width: 100%;
}

.seccio button {
  max-width: 100%;
}

.seccio button div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.seccio h1, h2, h3, h4 :first-child {
  margin-top: 0;
}

.seccio li button {
  max-width: 100%;
  justify-content: left;
  text-align: left;
}

.seccio .info-btn {
  margin-right: 1rem;
  margin-bottom: 2rem;
}

.control-group {
  text-align: center;
  margin: 2rem 0 2rem 0;
}

.control-group button {
  margin: 0 1rem 1rem 0;
}

/* === Loading === */

.progress {
  margin: 3rem;
}

/* === Error === */

.err-msg {
  grid-column: 1 / -1;
  text-align: center;
  padding: var(--theme-spacing-unit-x2);
  margin: 3rem auto;
}

.err-msg .action-btn {
  margin: 2rem;
}

.err-icon {
  vertical-align: bottom;
  margin: 0 0.5rem;
}

/* === Presentacio === */

.pwa-button {
  display: none;
}

.pwa-button svg {
  width: 1.5em;
}

/* === Programes === */

.prog-list {
  width: 100%;
  max-width: 50rem;
  background-color: var(--theme-palette-background-paper);
  /* 
  ** Enable this to have a scrolling list:
  ** max-height: 80vh; 
  ** overflow-y: auto; 
  */
}

.prog-list>li {
  margin-right: -1rem;
}

.prog-list .list-button {
  padding-left: 0;
  padding-right: 4rem;
}

.prog-logo {
  max-width: 100px;
  max-height: 100px;
  float: left;
  margin-right: 1rem;
}

.left-icon {
  margin-right: 0.5rem;
  vertical-align: bottom;
}

.right-icon {
  margin-left: 0.5rem;
  vertical-align: bottom;
}

.torna {
  grid-column: 1 / -1;
  justify-self: start;
}

.select-all {
  float: right;
  margin-right: 1.5rem;
}

.programa #descripcio {
  overflow: auto;
  margin-bottom: 1rem;
}

.programa h4 {
  margin-bottom: 0.2rem;
}

.programa li {
  margin-bottom: 0.5rem;
}

/* === Selector de programes === */

.dialog-content {
  width: 40rem;
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
}

.dialog-content .info {
  margin-top: 0.5rem;
}

.nivells {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
}

.select-list {
  max-width: 40rem;
}

.select-progs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.select-progs>* {
  flex-grow: 1;
}

/* === Projectes === */

.projecte {
  grid-column: 1 / -1;
  justify-self: start;
  width: 100%;
  max-width: 750px;
}

.projecte .info-proj {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-gap: 1rem;
  align-items: center;
  justify-items: start;
  margin: 2rem auto;
}

.info-proj img {
  max-width: 100%;
  max-height: 60px;
}

.proj-media {
  width: 100%;
  max-width: 700px;
}

.proj-pdf {
  width: 100%;
  height: 1100px;
  max-height: 150vw;
  margin: 1rem auto;
}

.proj-video {
  width: 100%;
  height: 500px;
  max-height: 80vw;
  margin: 1rem auto;
}

/* === Centres === */

.logo-nom-seccio {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
}

.seccio-logo {
  max-width: 160px;
  max-height: 160px;
  margin-right: 2rem;
  margin-bottom: 1rem;
}

.adreca {
  font-size: 11pt;
  margin-bottom: 1rem;
}

.mapa-centre {
  width: 100%;
  height: 600px;
  max-height: 80vh;
}

.progs-centre {
  text-align: left;
  width: 100%;
}

.centre h4 {
  margin-bottom: 0.2rem;
}

.centre h3 {
  margin-bottom: 0.5rem;
}

/* === Zones === */

.se-logo {
  background-color: cadetblue;
  padding: 10px;
}

.zona h4 {
  margin-bottom: 0.2rem;
}

.zona h3 {
  margin-bottom: 0.5rem;
}

.seccio .zona-prog {
  display: grid;
  grid-template-columns: 50px 1fr;
  margin-right: 2rem !important;
}

.zona-prog-logo {
  grid-area: 1 / 1 / 3 / 2;
}

/* === Cerca === */

.cerca {
  grid-column: 1 / -1;
}

.cerca .close-query-btn {
  display: block;
  margin: 2rem auto;
}

.item-found {
  cursor: pointer;
}

.page-num {
  margin: 0 0.2rem;
}

.page-link {
  cursor: pointer;
  text-decoration: underline;
}

/* === Mapa === */

.mapa {
  width: 100%;
  height: 80vh;
}

.cursos svg text {
  display: none;
}

.smapa .no-padding-top {
  padding-top: 0;
}

/* === Footer === */

footer {
  background-color: var(--gencat-gray-background);
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  clear: both;
}

footer .footer-content {
  margin: 1rem auto;
  border-top: 2px solid var(--gencat-gray-line);
  padding: 1rem;
  display: grid;
  grid-template-columns: 110px 1fr;
  grid-gap: 1rem;
  max-width: var(--max-width);
}

footer .logo-gencat {
  background: url("assets/logo_generalitat_gris.png") no-repeat 0 0;
  width: 101px;
  height: 27px;
  text-indent: -555em;
  display: block;
  text-decoration: none;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
}

footer .copyright {
  font-size: 0.7rem;
  color: #666;
}

.copyright a {
  color: inherit;
  text-decoration: none;
}

.copyright a:hover {
  text-decoration: underline;
}

/* Small devices */

@media (max-width: 600px) {
  #root {
    padding-bottom: 0;
  }
  main {
    padding: 0;
  }
  footer {
    position: relative;
    margin-top: 1rem;
  }
  footer .footer-content {
    grid-template-columns: 1fr;
    padding: 1rem 0;
  }
  body .stretch-dlg {
    margin: 0.5rem;
    max-height: calc(100% - 20px);
  }
  section .cursos {
    padding: 1rem 0;
  }
  section .no-padding-h-small {
    padding-left: 0;
    padding-right: 0;
  }
  .zona .small-padding-h {
    padding-left: 0;
    padding-right: 0;
  }
  .logo-nom-seccio {
    flex-wrap: wrap;
  }
  .seccio-logo {
    max-width: 80px;
    max-height: 80px;
  }
}

/* Printed documents */

@media print {
  #root header, .filler {
    display: none;
  }
  footer {
    position: relative;
    max-width: 800px;
    margin: auto;
    background-color: white;
  }
  main .torna, main .csv-btn {
    display: none;
  }
  main {
    display: block;
    max-width: 800px;
  }
  main .MuiPaper-root {
    box-shadow: none;
  }
  section .MuiExpansionPanelSummary-expandIcon {
    display: none;
  }
  section {
    margin-bottom: 1rem 0;
  }
  .smapa, footer, section li, .no-break {
    page-break-inside: avoid;
  }
}